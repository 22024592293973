import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";

export default class ActivateMandate extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    error: "",
    values: [],
    remitaTransRef: "",
    mandateId: "",
    requestId: "",
    authParams: [],
  };

  submitForm = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const auth = [...this.state.authParams];
    auth.map((item, i) => {
      item.value = this.state.values[i];
    });
    const mandate = {
      remitaTransRef: this.state.remitaTransRef,
      authParams: auth,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "api/validateOtp", {
        remitaTransRef: this.state.remitaTransRef,
        authParams: auth,
      })
      .then(({ data }) => {
        this.setState({ loading: false, error: data.status });
        setTimeout(() => {
          this.props.history.push("/login");
        }, 4500);
        // console.log(data)
      })
      .catch(({ response }) => {
        this.setState({ loading: false, error: response.data.status });
        console.log(response);
      });
  };

  handleChange(i, event) {
    const values = [...this.state.values];
    values[i] = event.target.value;
    this.setState({ values }, () => {
      console.log(this.state.values);
    });
  }
  componentDidMount() {
    const authParams = [];
    // console.log(JSON.parse(atob(this.props.match.params.hash)))
    const data = JSON.parse(atob(this.props.match.params.hash)).authParams;
    const dat = [];

    Object.entries(data[0])
      .sort()
      .forEach(([key, value]) => {
        if (key.includes("description")) {
          for (let i = 1; i <= 5; i++) {
            if (key == `description${i}`) dat.push(value);
          }
        }
      });

    Object.entries(data[0])
      .sort()
      .forEach(([key, value]) => {
        if (key.includes("param")) authParams.push({ [key]: value });
      });
    let values = [...this.state.values];
    values = dat;
    // console.log(values)
    // console.log(authParams)
    this.setState({
      authParams,
      values,
      requestId: JSON.parse(atob(this.props.match.params.hash)).requestId,
      mandateId: JSON.parse(atob(this.props.match.params.hash)).mandateId,
      remitaTransRef: JSON.parse(atob(this.props.match.params.hash))
        .remitaTransRef,
    });
  }

  render() {
    const bg = {
      backgroundColor: "#7c7c7d",
    };
    return (
      <Container fluid style={bg}>
        <Row style={{ height: "100vh" }} className="align-items-center">
          <Col xs="12" sm="6" className="mx-auto">
            <Jumbotron
              className="ui-box-shadow-dark-light"
              style={{ backgroundColor: "#fff" }}
            >
              <Col sm="6" className="mx-auto mb-4">
                <a href="/">
                  <img className="w-100" src="/assets/img/logo.png" alt="" />
                </a>
              </Col>
              <h6 className="text-center my-3">
                Please Enter Details Below to Activate Loan
              </h6>
              <Form onSubmit={this.submitForm}>
                {this.state.values
                  ? this.state.values.map((el, i) => (
                      <FormGroup key={i}>
                        <Input
                          type="text"
                          value={el || ""}
                          onChange={(e) => {
                            this.handleChange(i, e);
                          }}
                        />
                      </FormGroup>
                    ))
                  : null}
                <Button
                  type="submit"
                  block
                  className="btnprimary"
                  color="info"
                  size="md"
                  disabled={this.state.loading}
                >
                  Activate
                </Button>
                <Label>{this.state.error}</Label>
              </Form>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    );
  }
}
