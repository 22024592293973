import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";

class Header extends Component {
  state = {
    isOpen: false,
  };

  logoutUser = () => {
    // logout user and redirect to login
    axios
      .post(process.env.REACT_APP_BASE_URL + "api/logoutUser", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.msg) {
          localStorage.removeItem("token");
          this.props.history.push("/");
        }
      })
      .catch((err) => {
        // logout user and redirect to login
        console.error(err.response);
      });
  };

  render() {
    return (
      <Navbar className="mb-4" color="dark" dark expand="md">
        <NavbarBrand href="/dashboard">
          <img className="w-50" src="/assets/img/logo.png" alt="" />
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/* <NavItem>
              <NavLink href="/newloan">Apply for New Loan</NavLink>
            </NavItem> */}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {this.props.client ? this.props.client.firstname : null}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="/profile">Profile</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.logoutUser}>Log Out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default Header;
