import React, { Component } from "react";
import { Container, Row, Col, Table, Button } from "reactstrap";
import FontAwesome from "react-fontawesome";
import axios from "axios";
import numeral from "numeral";
import Script from "react-load-script";

import Loan from "./widgets/Loan";

class Dashboard extends Component {
  state = {
    InterestCharged: "",
    principalExpected: "",
    principalPaid: "",
    totalLoans: "",
    loans: "",
    modal: false,
  };

  toggle = (id) => {
    this.setState({
      modal: id,
    });
  };

  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `api/getLoans/${this.props.client.clientId}`
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          loans: resp.data,
        });
        // this.setState({
        //   InterestCharged: numeral(resp.data[0].InterestCharged).format('0,0.00'), principalExpected: numeral(resp.data[0].principalExpected).format('0,0.00'), principalPaid: numeral(resp.data[0].principalPaid).format('0,0.00'), totalLoans: resp.data[0].totalLoans, loans: resp.data.slice(1),
        // });
        // the splice the only reutrn the needed data from mifos
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  // <Loan modal={this.state.modal} toggle={this.toggle} repaymentSchedulePeriod={item.repaymentSchedule.periods.splice(1)} {...item} client={this.props.client} no={i + 1} key={item.id} />
  // splice 1 will only return te periods
  renderLoans = (loans) =>
    loans
      ? loans.map((item, i) => (
          <Loan
            modal={this.state.modal}
            toggle={this.toggle}
            {...this.props}
            {...item}
            client={this.props.client}
            no={i + 1}
            key={item.id}
          />
        ))
      : null;

  render() {
    return (
      <Container>
        {/* <Script
            url="https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js"
            onCreate={() => {
              '';
            }}
            onError={() => {
              '';
            }}
            onLoad={() => {
              '';
            }}
          /> */}
        <Row className="justify-content-center">
          <Col lg={4} md={6}>
            <div
              onClick={() => this.props.history.push("/newloan")}
              style={{ cursor: "pointer" }}
              className="card-counter btnsecondary"
            >
              <FontAwesome tag="i" name="user-circle" />
              <span className="count-numbers">New Loan</span>
              <span className="count-name">Apply</span>
            </div>
          </Col>
          {/* <Col lg={4} md={6}>
              <div style={{ cursor: 'pointer' }} className="card-counter btnprimary ">
                <FontAwesome tag="i" name="credit-card" />
                <span className="count-numbers">

                 Topup Loan
                </span>
                <span className="count-name">Apply</span>
              </div>
            </Col> */}
        </Row>
        {/* <Row>
            <Col lg={4} md={6}>
              <div className="card-counter btnsecondary">
                <FontAwesome tag="i" name="user-circle" />
                <span className="count-numbers">
₦
                  {this.state.principalExpected}
                </span>
                <span className="count-name">Principal Borrowed</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="card-counter btnprimary ">
                <FontAwesome tag="i" name="credit-card" />
                <span className="count-numbers">
₦
                  {this.state.principalPaid}
                </span>
                <span className="count-name">Principal Paid</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="card-counter danger">
                <FontAwesome tag="i" name="university" />
                <span className="count-numbers">
₦
                  {this.state.InterestCharged}
                </span>
                <span className="count-name">Total Interest Borrowed</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="card-counter danger">
                <FontAwesome tag="i" name="database" />
                <span className="count-numbers">

                {this.state.totalLoans}
              </span>
                <span className="count-name">Total Loans Taken</span>
              </div>
            </Col>
          </Row>

         */}

        <Row>
          <Col className="mt-5 text-center" md={12}>
            <h3>Loans Taken</h3>
          </Col>
          <Col md={12} className="my-4">
            <Table dark striped hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Loan Amount</th>
                  <th>Repayment</th>
                  <th>Loan Tenure</th>
                  <th>Submitted On</th>
                  <th>Maturity Date</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>{this.renderLoans(this.state.loans)}</tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Dashboard;
