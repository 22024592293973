import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";

export default class Login extends Component {
  state = {
    phone: "",
    password: "",
    loading: false,
    error: "",
  };

  resetPassword = async () => {
    try {
      if (!this.state.phone) {
        alert("Please input your phone number");
      } else if (
        window.confirm("Are you sure you want to reset your password")
      ) {
        const { data } = await axios.post(
          process.env.REACT_APP_BASE_URL + "api/resetPassword",
          { phoneNumber: this.state.phone }
        );
        //  console.log(data);
        if (data) {
          alert(`Reset password has been sent to ${data.email}`);
        } else {
          alert("Phone number not found");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  submitForm = (event) => {
    event.preventDefault();

    if (this.state.phone && this.state.password) {
      //  console.log('in submit', { ...this.state });
      this.setState({ loading: true });
      const load = {
        ...this.state,
      };

      axios
        .post(process.env.REACT_APP_BASE_URL + "api/loginUser", load)
        .then((resp) => {
          //   console.log(resp.data);
          if (resp.data.isAuth) {
            localStorage.setItem("token", resp.data.token);
            this.props.history.push("/dashboard");
          }
        })
        .catch((err) => {
          console.error(err.response);
          if (!err.response.data.isAuth) {
            this.setState({ loading: false, error: err.response.data.message });
            return;
          }
          this.setState({ loading: false, error: "An error Occured" });
        });
    }
  };

  render() {
    // const jumb = {
    //   WebkitBoxShadow: '0px 0px 12px 1px rgba(0,0,0,0.4)',
    //   MozBoxShadow: '0px 0px 12px 1px rgba(0,0,0,0.4)',
    //   boxShadow: '0px 0px 12px 1px rgba(0,0,0,0.4)',
    //   backgroundColor: '#fff',
    // };
    const bg = {
      background:
        "url(/assets/img/checkoff-login-bg.jpg) no-repeat center center fixed",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover",
    };
    return (
      <Container fluid style={bg}>
        <Row style={{ height: "100vh" }} className="align-items-center">
          <Col xs="12" sm="4" className="mx-auto">
            <Col sm="6" className="mx-auto mb-5">
              <a href="/">
                <img className="w-100" src="/assets/img/logo.png" alt="" />
              </a>
            </Col>
            <Jumbotron
              className="ui-box-shadow-dark-light"
              style={{ backgroundColor: "#fff" }}
            >
              {/* <h4 className="text-center mb-5">Login into your Account</h4> */}
              <Form onSubmit={this.submitForm}>
                <FormGroup>
                  <Label for="exampleEmail">Phone Number</Label>
                  <Input
                    type="number"
                    value={this.state.phone}
                    placeholder="Enter your Phone Number"
                    onChange={(e) => {
                      this.setState({ phone: e.target.value });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">Password</Label>
                  <Input
                    type="password"
                    value={this.state.password}
                    placeholder="Enter your password"
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                </FormGroup>
                <Button
                  type="submit"
                  block
                  className="btnprimary"
                  color="info"
                  disabled={this.state.loading}
                >
                  Login to your Account
                </Button>
                <span
                  onClick={this.resetPassword}
                  className="mrgt1"
                  style={{ textAlign: "center", fontSize: 14 }}
                >
                  Forgot Password
                </span>
                <br />
                <Label>{this.state.error}</Label>
              </Form>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    );
  }
}
