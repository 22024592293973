import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import FontAwesome from "react-fontawesome";
import Header from "./Header";

export default function (ComposedClass, reload, page = "") {
  class AuthenticationCheck extends Component {
    state = {
      loading: true,
      client: "",
    };

    componentDidMount() {
      axios
        .post(process.env.REACT_APP_BASE_URL + "api/auth", {
          token: localStorage.getItem("token")
            ? localStorage.getItem("token")
            : "empty",
        })
        .then((response) => {
          this.setState({ loading: false, client: response.data });

          // console.log(response.data);
          if (!response.data.isAuth) {
            // if client is not logged in and reload page is true, redirect to login, if reload is true, you hae to be logged in
            if (reload) {
              this.props.history.push("/login");
            }
          } else {
            // if the client is logged in and the reload is false go the the dashboard
            if (reload === false) {
              this.props.history.push("/dashboard");
            }
          }
        })
        .catch((err) => {
          console.error(err.response);
        });
    }

    render() {
      if (this.state.loading) {
        return (
          <Container>
            {" "}
            <Row>
              <Col md={3} className="mx-auto my-3 text-center">
                <FontAwesome
                  name="spinner"
                  size="2x"
                  style={{ color: "#c82590" }}
                  spin
                />
              </Col>
            </Row>
          </Container>
        );
      }
      return (
        <>
          {page === "login" ||
          page === "apply" ||
          page === "home" ||
          page === "about" ||
          page === "contact" ? null : (
            <Header {...this.props} client={this.state.client} />
          )}
          <ComposedClass {...this.props} client={this.state.client} />
        </>
      );
    }
  }

  return AuthenticationCheck;
}
